import QRCode from 'qrcode';
import React from 'react';
import { userLoggedIn, captureException } from '../../../utils/tracking';
import * as request from '../../../use/universalRequests';
import * as config from './interface';

const { REACT_APP_API_BASE_URL = '', REACT_APP_DOMAIN_NAME = '' } = process.env;

const delayedRedirection = (redirectUrl: string, ms = 2000) => {
  setTimeout(() => window.location.replace(redirectUrl), ms);
};

const normalizeError = (err: Error): { origin: string } & Error => ({
  message: (err instanceof Error ? err.message : 'Authentication failed'),
  name: (err instanceof Error ? err.name : 'Error'),
  stack: (err instanceof Error && err.stack) ? err.stack : undefined,
  origin: 'Centralized authentication',
});

export const generateRedirectUrl = (
  origin: string,
  tenant: string,
  environment: string,
) => {
  const prefix = origin.includes('beta') ? `${tenant}.beta` : tenant;
  return `https://${prefix}.${environment}.${REACT_APP_DOMAIN_NAME}`;
};

export const checkSso = async (
  payload: config.CheckSSOPayload,
  methods: config.CheckSSOMethods,
) => {
  const { email, tenant } = payload;
  const { setStep, setLoading, setSsoConfig } = methods;

  setLoading(true);
  const url = `${REACT_APP_API_BASE_URL}/auth/sso-details/?email=${email}${
    tenant && `&tenant=${tenant}`
  }`;

  const response = await request.universalGetRequest<config.CheckSSOResponse>(
    encodeURI(url),
  );
  if (response && response.data && response.status === 200) {
    const { ssoConfig } = response.data;
    setStep(config.PASSWORD_INPUT);
    setSsoConfig(response.data.ssoConfig);

    localStorage.setItem(config.SSO_CONFIG, JSON.stringify(ssoConfig));
  }

  setLoading(false);
};

export const fetchTenantList = async (
  payload: config.CheckSSOPayload,
  methods: config.FetchTenantListMethods,
) => {
  const { email } = payload;
  const { setLoading, setStep, setTenantList } = methods;

  setLoading(true);
  const url = `${REACT_APP_API_BASE_URL}/auth/tenant-list/?email=${encodeURIComponent(email)}`;

  const response = await request.universalGetRequest<config.FetchTenantListResponse>(encodeURI(url));
  if (response && response.data && response.status === 200) {
    const { tenantListWithSubdomain = [] } = response.data;
    if (tenantListWithSubdomain.length > 0) {
      setStep(config.TENANT_SELECTION);
      setTenantList(tenantListWithSubdomain);
    } else {
      setStep(config.PASSWORD_INPUT);
    }
  }

  setLoading(false);
};

export const authenticate = async (
  payload: config.AuthenticatePayload,
  methods: config.AuthenticateMethods,
) => {
  const { email, password } = payload;
  const { setStep, setLoading, setSecretCode, setErrorMessage } = methods;
  setLoading(true);

  if (email.length && password.length) {
    const url = `${REACT_APP_API_BASE_URL}/auth/authenticate`;

    try {
      const response = await request.universalPostRequest<
      config.AuthenticateResponseInterface,
      config.AuthenticatePayload
    >(url, payload);
      if (response && response.data && response.status === 200) {
        const { subdomain, tenant, accessToken, user } = response.data;

        userLoggedIn({ ...user, tenant, subdomain });

        localStorage.setItem(config.AUTH_INFO, JSON.stringify(response.data));

        const redirectUrl = `${generateRedirectUrl(
          window.location.origin,
          tenant,
          subdomain,
        )}?accessToken=${accessToken}`;

        delayedRedirection(redirectUrl);
      } else {
        const { code = '', error = '', mfaSecretCode = '' } = response;
        setErrorMessage(error);

        if (code === config.MFA_CODE_MISSING) {
          setErrorMessage('');
          setStep(config.MFA_CODE_MISSING);
        } else if (code === config.NEW_PASSWORD_REQUIRED) {
          setErrorMessage('');
          setStep(config.NEW_PASSWORD_REQUIRED);
        } else if (code === config.MFA_SETUP_REQUIRED) {
          setStep(config.MFA_SETUP_REQUIRED);
          setSecretCode(mfaSecretCode);
        }
        setLoading(false);
      }
    } catch (err) {
      setErrorMessage('Authentication failed. Please try again!');
      setLoading(false);
      const errorObject = normalizeError(err as unknown as Error);
      captureException({ ...errorObject as unknown as Error });
    }
  }
};

export const authenticateMFA = async (
  payload: config.AuthenticateMFAPayload,
  methods: config.AuthenticateMFAMethods,
) => {
  const { email, password, mfaCode } = payload;
  const { setLoading, setErrorMessage } = methods;
  setLoading(true);

  if (email.length && password.length && mfaCode.length) {
    const url = `${REACT_APP_API_BASE_URL}/auth/authenticate/mfa`;

    try {
      const response = await request.universalPostRequest<
      config.AuthenticateResponseInterface,
      config.AuthenticateMFAPayload
    >(url, payload);
      if (response && response.data && response.status === 200) {
        const { subdomain, tenant, accessToken, user } = response.data;
        userLoggedIn({ ...user, tenant, subdomain });

        localStorage.setItem(config.AUTH_INFO, JSON.stringify(response.data));

        const redirectUrl = `${generateRedirectUrl(
          window.location.origin,
          tenant,
          subdomain,
        )}?accessToken=${accessToken}`;

        delayedRedirection(redirectUrl);
      } else {
        const { error = '' } = response;
        setErrorMessage(error);
        setLoading(false);
      }
    } catch (err) {
      setErrorMessage('Authentication failed. Please try again!');
      setLoading(false);
      const errorObject = normalizeError(err as unknown as Error);
      captureException({ ...errorObject as unknown as Error });
    }
  }
};

export const authenticatePassword = async (
  payload: config.AuthenticatePasswordPayload,
  methods: config.AuthenticatePasswordMethods,
) => {
  const { email, password, newPassword } = payload;
  const { setStep, setLoading, setErrorMessage, setSecretCode } = methods;
  setLoading(true);

  if (email.length && password.length && newPassword.length) {
    const url = `${REACT_APP_API_BASE_URL}/auth/authenticate/password`;

    try {
      const response = await request.universalPutRequest<
      config.AuthenticateResponseInterface,
      config.AuthenticatePasswordPayload
    >(url, payload);
      if (response && response.data && response.status === 200) {
        const { subdomain, tenant, accessToken, user } = response.data;
        userLoggedIn({ ...user, tenant, subdomain });

        localStorage.setItem(config.AUTH_INFO, JSON.stringify(response.data));

        const redirectUrl = `${generateRedirectUrl(
          window.location.origin,
          tenant,
          subdomain,
        )}?accessToken=${accessToken}`;

        delayedRedirection(redirectUrl);
      } else {
        const { code = '', error = '', mfaSecretCode = '' } = response;
        setErrorMessage(error);
        if (code === config.MFA_SETUP_REQUIRED) {
          setErrorMessage('');
          setStep(config.MFA_SETUP_REQUIRED);
          setSecretCode(mfaSecretCode);
          setLoading(false);
        }
      }
    } catch (err) {
      setErrorMessage('Authentication failed. Please try again!');
      setLoading(false);
      const errorObject = normalizeError(err as unknown as Error);
      captureException({ ...errorObject as unknown as Error });
    }
  }
};

export const generateQRCodeUrl = (
  email: string,
  secretCode: string,
  setSrc: React.Dispatch<React.SetStateAction<string>>,
) => {
  QRCode.toDataURL(
    `otpauth://totp/${email}?secret=${secretCode}&issuer=${window.location.host}`,
  ).then(setSrc);
};
